import React, { useEffect, useState } from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// import AuthAdmin from "./AuthAdmin";
import HomeComponent from "../Component/Home/HomeComponent";
import ConservatismComp from "../Component/ConservatismComp/ConservatismComp";
import Student from "../Component/ConservatismComp/Student";
// import AddLost from "../Component/AddLost/AddLost";
import LonginComponent from "../Component/Login/LonginComponent";
// import AllCatemessage from "../Component/AllLostAndFoundCom/AllCatemessage";
// import AllLostAndFoundCom from "../Component/AllLostAndFoundCom/AllLostAndFoundCom";
// import AddMessage from "../Component/AddLost/AddMessage";
import JobPortalClint from "../Component/JobPortal/JobPortalClint";
 
// import MessageDetailsComponent from "../Component/WinnerDetails/MessageDetailsComponent";
import VendorStoreCom from "../Component/VendorStore/VendorStoreCom";
import VendorProList from "../Component/VendorStore/VendorProList";
import VendorListing from "../Component/VendorStore/VendorListing";
import RegisterComponent from "../Component/Login/RegisterComponent";
import Account from "../Component/Account/Account";
import Seller from "../Component/Seller/Seller";
import SaleComponent from "../Component/Seller/SaleCom/SaleComponent";
import SalePanding from "../Component/Seller/SaleCom/SalePanding";
import SaleCancil from "../Component/Seller/SaleCom/SaleCancil";
import Saleconfirmed from "../Component/Seller/SaleCom/Saleconfirmed";
import Saleprocessing from "../Component/Seller/SaleCom/Saleprocessing";
import SaleoutofStock from "../Component/Seller/SaleCom/SaleoutofStock";
import Saledelivered from "../Component/Seller/SaleCom/Saledelivered";
import Salereturned from "../Component/Seller/SaleCom/Salereturned";
import ListIngComponent from "../Component/Seller/Listing/ListIngComponent";
import AddOListComponentScreen from "../Component/Seller/Listing/AddOListComponentScreen";
import ListingUpdatecom from "../Component/Seller/Listing/ListingUpdatecom";
import ProScreen from "../Component/Seller/Product/ProScreen";
import ProAddScreen from "../Component/Seller/Product/ProAddScreen";
import ProEditScreen from "../Component/Seller/Product/ProEditScreen";
import PackageComponent from "../Component/Seller/Package/PackageComponent";
import SettingCom from "../Component/setting";
import SettingComponent from "../Component/setting/SettingComponent";
import BankInformation from "../Component/setting/BankInformation";
import PaybleScreen from "../Component/Seller/SaleCom/PaybleScreen";
import Withdrawal from "../Component/Seller/SaleCom/Withdrawal";
import Order from "../Component/Account/Order";
import LostAndFound from "../Component/Account/LostAndFound";
import Certificates from "../Component/Account/Certificates";
import DirectTeam from "../Component/Account/DirectTeam";
import TotalTeam from "../Component/Account/TotalTeam";
import MyIncome from "../Component/Account/MyIncome";
import SupportTicket from "../Component/Account/SupportTicket";
// import LostAndFoundDetail from "../Component/AddLost/LostAndFoundDetail";
import AllProducts from "../Component/AllProducts/AllProducts";
import AllListing from "../Component/AllProducts/AllListing";
// import AllVideoCommmmCom from "../Component/AllLostAndFoundCom/AllVideoCom";
import AllSellerCom from "../Component/AllSellerCom/AllSellerCom";
import ListingDetailsPubl from "../Component/ListingDetails/ListingDetailsPubl";
import ProductDetailsPub from "../Component/ListingDetails/ProductDetailsPub";
import JobApply from "../Component/JobPortal/JobApply";
import ApplyedJobScreen from "../Component/Account/ApplyedJobScreen";
import JobScreenSeller from "../Component/Seller/JobList/JobScreenSeller";
import WinnerDetails from "../Component/WinnerDetails/WinnerDetails";
import CategoryMainPro from "../Component/CategoryProduct/CategoryMainPro";
import CategorySubSubPro from "../Component/CategoryProduct/CategorySubSubPro";
import JobsellerList from "../Component/Seller/JobList/JobsellerList";
import JobScreen from "../Component/Seller/JobList/JobScreen";
import SellerJobAddScreen from "../Component/Seller/JobList/SellerJobAddScreen";
import JobPortalClintNew from "../Component/JobPortal/JobPortalClintNew";
// import WeddingMessageComponent from "../Component/AllLostAndFoundCom/WeddingMessageComponent";
// import Soksandhesh from "../Component/AllLostAndFoundCom/Soksandhesh";
import AllJobListComponent from "../Component/JobPortal/AllJobListComponent";
import JobsCategoryWise from "../Component/JobPortal/JobsCategoryWise";
import EducationDetailsPage from "../Component/JobPortal/EducationDetailsPage";
import EducationPage from "../Component/JobPortal/EducationPage";
import JobViewDetails from "../Component/JobPortal/JobViewDetails";
import GenerateCertificateCom from "../Component/GenerateCertificateCom/GenerateCertificateCom";
import Categorys from "../Component/CategoryProduct/Categorys";
import ProductDetails2 from "../Component/ListingDetails/ProductDetails2";
import JobViewDetails2 from "../Component/JobPortal/JobViewDetails2";
import CartComponent from "../Component/Cart/CartComponent";
import ShoppingAddress from "../Component/Cart/ShoppningAddress";
import Checkout from "../Component/Cart/Checkuot";
import SubCategoryProductAndCategory from "../Component/CategoryProduct/SubCategoryProductAndCategory";
import PravicyPolicy from "../Component/PravicyPolicy";
import TremsAndCondition from "../Component/TremsAndCondition";
import SubsubCategoryProductandCategory from "../Component/CategoryProduct/SubsubCategoryProductandCategory";
import BlogList from "../Component/Seller/Blog/BlogList";
import AddBlog from "../Component/Seller/Blog/AddBlog";
import EditBlog from "../Component/Seller/Blog/EditBlog";
import RefferalProgram from "../Component/Account/RefferalProgram";
import ReferRegistaercom from "../Component/Login/ReferRegistaercom";
import RefRegisterComponent from "../Component/Login/RefRegisterComponent";
import Wedding from "../Component/Wedding/Wedding";
import WeddingForm from "../Component/Wedding/WeddingForm";
import Weddingprofile from "../Component/Wedding/Weddingprofile";
import Weddinglisting from "../Component/Wedding/Weddinglisting";
import BlogListpub from "../Component/Blog/BlogListpub";
import BlogDetailsPub from "../Component/Blog/BlogDetailsPub";
import GenologyScreen from "../Component/Account/GenologyScreen";
import LevelIncome from "../Component/Account/LevelIncome";
import DirectIncome from "../Component/Account/DirectIncome";
import MatchingIncome from "../Component/Account/MatchingIncome";
import ReDirectIncome from "../Component/Account/ReDirectIncome";
import RelevelIncome from "../Component/Account/RelevelIncome";
import BonusIncome from "../Component/Account/BonusIncome";
import RematchingIcome from "../Component/Account/RematchingIcome";
import DonationScreen from "../Component/Account/DonationScreen";
import DonationDetailScreen from "../Component/Account/DonationDetailScreen";
import LevelAchiveIncome from "../Component/Account/LevelAchiveIncome";

function AppRoutes() {
  const [first, setfirst] = useState("");
  useEffect(() => {
    // localStorage.setItem("access_token_vendor", access_token);
    const token = localStorage.getItem("theme");

    return () => {};
  }, []);

  return (
    // < Switch >
    //   < Route exact path="/" component={UserTable} />
    //   < Route exact path="/login" component={Login} />
    // </Switch>

    // <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomeComponent />} />
      <Route path="/conservatism" element={<ConservatismComp />} />
      <Route path="/student" element={<Student />} />

      <Route path="/login" element={<LonginComponent />} />
      <Route path="/vendor-login" element={<LonginComponent />} />
      <Route path="/register" element={<RegisterComponent />} />
      <Route path="/referalsignup" element={<RefRegisterComponent />} />
      {/* <Route path="/add-lost-found" element={<AddLost />} /> */}
      {/* <Route path="/all-message" element={<AllCatemessage />} /> */}
      {/* <Route path="/all-lost-and-found" element={<AllLostAndFoundCom />} /> */}
      {/* <Route path="/message" element={<AddMessage />} /> */}
      <Route path="/job-portal2" element={<JobPortalClint />} />
      <Route path="/job-portal" element={<JobPortalClintNew />} />
      <Route path="/all-job" element={<AllJobListComponent />} />
 
      <Route path="/products" element={<AllProducts />} />
      <Route path="/listings" element={<AllListing />} />
      {/* <Route path="/all-video" element={<AllVideoCommmmCom />} /> */}
      <Route path="/all-seller" element={<AllSellerCom />} />
      {/* <Route path="/weddingwishes" element={<WeddingMessageComponent />} /> */}
      {/* <Route path="/shoksandesh" element={<Soksandhesh />} /> */}
      <Route path="/cart" element={<CartComponent />} />
      <Route path="/shopping-address" element={<ShoppingAddress />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/blogs-news" element={<BlogListpub />} />
      <Route path="/blog-details/:id" element={<BlogDetailsPub />} />

      {/* <Route path="/lost-and-found/:id" element={<LostAndFoundDetail />} /> */}
      <Route path="/winner/:id" element={<WinnerDetails />} />
      {/* <Route path="/winner/:id" element={<WinnerDetails />} /> */}
      <Route path="/category-job/:id" element={<JobsCategoryWise />} />

      <Route path="/accounts" element={<Account />} />
      <Route path="/accounts/donation" element={<DonationScreen />} />
      <Route path="/accounts/confirm_package/:id" element={<DonationDetailScreen />} />
      <Route path="/accounts/refferal" element={<RefferalProgram />} />
      <Route path="/privacy-policy" element={<PravicyPolicy />} />
      <Route path="/terms-and-condition" element={<TremsAndCondition />} />
      <Route path="/accounts/donated" element={<Order />} />
      <Route path="/accounts/lost-and-found" element={<LostAndFound />} />
      <Route path="/accounts/certificates" element={<Certificates />} />
      <Route
        path="/generate-certificate"
        element={<GenerateCertificateCom />}
      />
      <Route path="/accounts/apply-jobs" element={<ApplyedJobScreen />} />
      <Route path="/accounts/direct-team" element={<DirectTeam />} />
      <Route path="/accounts/level-income" element={<LevelIncome />} />
      <Route path="/accounts/level-achive-income" element={<LevelAchiveIncome />} />
      <Route path="/accounts/direct-income" element={<DirectIncome />} />
      <Route path="/accounts/bonus-income" element={<BonusIncome />} />
      <Route path="/accounts/matching-income" element={<MatchingIncome />} />
      <Route path="/accounts/redirect-income" element={<ReDirectIncome />} />
      <Route path="/accounts/relevel-income" element={<RelevelIncome />} />
      <Route path="/accounts/rematching-income" element={<RematchingIcome />} />
      <Route path="/accounts/genelogy" element={<GenologyScreen />} />
      <Route path="/accounts/total-team" element={<TotalTeam />} />
      <Route path="/accounts/my-income" element={<MyIncome />} />
      <Route path="/accounts/support-ticket" element={<SupportTicket />} />
      <Route path="/seller" element={<Seller />} />
      <Route path="/education" element={<EducationPage />} />
      <Route path="/listing/:id" element={<ListingDetailsPubl />} />
      {/* <Route path="/product/:id" element={<ProductDetailsPub />} /> */}
      <Route path="/product2/:id" element={<ProductDetails2 />} />
      <Route path="/product/:id" element={<ProductDetailsPub />} />
      <Route path="/job-apply/:id" element={<JobApply />} />
      <Route path="/job-details/:id" element={<JobViewDetails2 />} />
      <Route path="/category" element={<Categorys />} />
      <Route
        path="/sub-category/:id"
        element={<SubCategoryProductAndCategory />}
      />
      <Route
        path="/sub-sub-category/:id"
        element={<SubsubCategoryProductandCategory />}
      />
      <Route path="/education-details/:id" element={<EducationDetailsPage />} />
      <Route path="/category-products/:id" element={<CategoryMainPro />} />
      <Route path="/category-product/:id" element={<CategorySubSubPro />} />

      <Route path="/seller/sale" element={<SaleComponent />} />
      <Route path="/seller/sale/pending" element={<SalePanding />} />
      <Route path="/seller/sale/canceled" element={<SaleCancil />} />
      <Route path="/seller/sale/confirmed" element={<Saleconfirmed />} />
      <Route path="/seller/sale/processing" element={<Saleprocessing />} />
      <Route
        path="/seller/sale/out-for-delivery"
        element={<SaleoutofStock />}
      />
      <Route path="/seller/sale/delivered" element={<Saledelivered />} />
      <Route path="/seller/sale/returned" element={<Salereturned />} />

      <Route path="/seller/package" element={<PackageComponent />} />
      <Route path="/seller/applied-jobs" element={<JobScreenSeller />} />
      <Route path="/seller/setting" element={<SettingComponent />} />
      <Route path="/seller/bankinformation" element={<BankInformation />} />
      <Route path="/seller/my-income" element={<PaybleScreen />} />
      <Route path="/seller/withdrawal" element={<Withdrawal />} />

      <Route path="/seller/blog-list" element={<BlogList />} />
      <Route path="/seller/blog-list/add-blog" element={<AddBlog />} />
      <Route path="/seller/blog-list/edit-blog/:id" element={<EditBlog />} />
      <Route path="/seller/listing" element={<ListIngComponent />} />
      <Route path="/seller/joblist" element={<JobScreen />} />
      <Route path="/seller/job/add-jobs" element={<SellerJobAddScreen />} />
      <Route
        path="/seller/listing/add-listing"
        element={<AddOListComponentScreen />}
      />
      <Route
        path="/seller/listing/update-listing/:id"
        element={<ListingUpdatecom />}
      />

      {/* product seller */}
      <Route path="/seller/product" element={<ProScreen />} />
      <Route path="/seller/product/add-product" element={<ProAddScreen />} />
      <Route
        path="/seller/product/update-product/:id"
        element={<ProEditScreen />}
      />
      {/* <Route path="/seller/sale/delivered" element={<SalePanding />} /> */}
 
      {/* <Route path="/message/:id" element={<MessageDetailsComponent />} /> */}
      <Route path="/vendor-store/:id" element={<VendorStoreCom />} />
      <Route path="/vendor-store/product/:id" element={<VendorProList />} />
      <Route path="/vendor-store/listing/:id" element={<VendorListing />} />

      <Route path="/wedding" element={<Wedding />} />
      <Route path="/wedding/profile/:id" element={<Weddingprofile />} />
      <Route path="/wedding/wedding-form" element={<WeddingForm />} />
      <Route path="/wedding/list" element={<Weddinglisting />} />

      {/* </div> */}

      {/* <Route path="/login" element={<Login />} /> */}
      {/* <Route path="/register" element={<Register />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/forget-password" element={<ForgetPassword />} />

      <Route path="/" exact element={<AuthAdmin cmp={Home} />} />
      <Route path="/game-play" element={<AuthAdmin cmp={GamePlay} />} />
      <Route path="/my-wallet" element={<AuthAdmin cmp={MyWallet} />} />
      <Route path="/game-rate" element={<AuthAdmin cmp={GameRate} />} />
      <Route path="/contact-us" element={<AuthAdmin cmp={ContactUs} />} />
      <Route path="/add-point" element={<AuthAdmin cmp={AddPoint} />} />
      <Route path="/withdrawal" element={<AuthAdmin cmp={Withdrawal} />} />
      <Route path="/single-game" element={<AuthAdmin cmp={SingleGame} />} />
      <Route path="/jodi" element={<AuthAdmin cmp={Jodi} />} />
      <Route path="/single-pana" element={<AuthAdmin cmp={SinglePana} />} />
      <Route path="/double-pana" element={<AuthAdmin cmp={DoublePana} />} />
      <Route path="/tripal-pana" element={<AuthAdmin cmp={TripalPana} />} />
      <Route path="/half-sigma" element={<AuthAdmin cmp={HalfSigma} />} />
      <Route path="/full-sigma" element={<AuthAdmin cmp={FullSigma} />} />
      <Route path="/bid-history" element={<AuthAdmin cmp={BidHistory} />} />
      <Route path="/win-history" element={<AuthAdmin cmp={WinHistory} />} />
      <Route path="/proccess" element={<AuthAdmin cmp={Proccess} />} />
      <Route path="/success" element={<AuthAdmin cmp={Success} />} />
      <Route path="/failed" element={<AuthAdmin cmp={Failed} />} />
      <Route path="/profile" element={<AuthAdmin cmp={Profile} />} />
      <Route path="/chart" element={<AuthAdmin cmp={ChartList} />} />
      <Route path="/phonePe" element={<AuthAdmin cmp={PhonePe} />} />
      <Route path="/Paytem" element={<AuthAdmin cmp={Paytem} />} />
      <Route path="/googlePay" element={<AuthAdmin cmp={GooglePay} />} />
     
      <Route
        path="/change-password"
        element={<AuthAdmin cmp={ChangePassword} />}
      /> */}
      {/* <Route
        path="/change-password"
        element={<AuthAdmin cmp={ChangePassword} />}
      /> */}
      {/*  <Route path="/order-details/:id" element={<AuthAdmin cmp={Tracking} />} />
      <Route path="/wishlist" element={<Wishlist />} />
      <Route path="/shop-categories" element={<ShopCategories />} />
      <Route path="/shop-cart" element={<ShopCart />} />
      <Route path="/product-details/:id" element={<ProductDetails />} />

      <Route path="/contact-us" element={<ContactUs />} />
      <Route
        path="/choose-shipping-method"
        element={<ChooseShippingMethod />}
      />
      <Route path="/review-your-order" element={<ReviewYourOrder />} />
      <Route path="/choose-payment-method" element={<ChoosePaymentMethod />} />
      <Route path="/buy-now" element={<BuyNowPro />} />
      <Route path="/checkout-complate" element={<CheckoutComplete />} />
      <Route path="/response" element={<Responce />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/products" element={<ProductRight />} />
      <Route path="/searching-product" element={<SearchingProduct />} />
      <Route path="/orders/success" element={<OrderSuccess success={true} />} />
      <Route path="/orders/failed" element={<OrderSuccess success={false} />} />
      <Route path="/order/:id" element={<OrderStatus />} />
      <Route path="/buy-product-order/:id" element={<OrderStatus2 />} />
      <Route path="/loader" element={<Loader />} />
      <Route path="/terms-and-condition" element={<TermsAndCondition />} />
      <Route
        path="/refund-and-cancellation-policy"
        element={<RefundandCancellation />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route
        path="/checkout-details"
        element={<AuthAdmin cmp={CheckoutDetails} />}
      />
      <Route
        path="/checkout-details-address"
        element={<AuthAdmin cmp={CheckoutDetails2} />}
      /> */}

      {/* vandor */}
      {/* <Route path="/vendor/login" element={<AuthVendor cmp={Login} />} />
      <Route path="/vendor" element={<AuthVendor cmp={Vendor} />} />
      <Route
        path="/vendor/categories"
        element={<AuthVendor cmp={CategoriesList} />}
      />
      <Route path="/vendor/brand" element={<AuthVendor cmp={Brannd} />} />
     
      <Route path="/vendor/orders" element={<AuthVendor cmp={OrderList} />} />
      <Route
        path="/vendor/transation"
        element={<AuthVendor cmp={Transation} />}
      /> */}
    </Routes>
    // </BrowserRouter>
  );
}

export default AppRoutes;
