import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import ReactToPrint from "react-to-print";

// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import HeadingBack from "../HeadingBack";
import { Link, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
// import HeadingBack from "../../HeadingBack";

function DonationComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = navigate;
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const componentRef = useRef();
  const { confirm } = Modal;
  const page = navigate?.query?.page == null ? 1 : navigate?.query?.page;
  const search = navigate?.query?.search == null ? "" : navigate?.query?.search;

  const order_list = useSelector((state) =>
    state?.allapi?.get_seller_transaction_list
      ? state?.allapi?.get_seller_transaction_list
      : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    dispatch(allapiAction.getdonationTypeListsuser(page));

    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    // dispatch(allapiAction.getuserOrderDetails({ _id: route?.query?.id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    // dispatch(
    //   allapiAction.cancelOrderByAdmin({
    //     _id: statusUpTrId?._id,
    //     user_id: statusUpTrId?.user_id?._id,
    //     description: statusUpTrStatus,
    //     page: page,
    //   })
    // );
    setstatusUpTrStatus("");
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };

  const shippingCharge = parseInt(order_details?.delivery_charges);
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd =
    (order_details.sub_total * order_details?.gst) / 100;

  const totalPrice = order_details?.product_price; // Total price inclusive of GST
  const gstRate = order_details?.gst; // GST rate (5%)

  const basePrice = totalPrice / (1 + gstRate / 100);

  console.log("Base Price (Net Amount): $" + basePrice.toFixed(2));

  const basePriceTotal = basePrice * order_details?.itme_count;

  const perProgst = totalPrice - basePrice;
  console.log(perProgst?.toFixed(2));
  console.log(basePriceTotal?.toFixed(2));

  console.log(order_details);
  const totalgst = order_details?.sub_total - basePriceTotal;
  console.log(totalgst?.toFixed(2));

  const totalSubtotalPricegst = totalSubtotalPricegstdd;

  const totalAmount = order_details?.sub_total + shippingCharge;
  // const totalAmount =
  //   order_details?.sub_total + shippingCharge + totalSubtotalPricegstdd;

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Image",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {/* {item?.user_id?.fullName} */}
              <img
                src={URL?.API_BASE_URL + item?.colorId?.featureImage}
                alt=""
                style={{ width: "70px" }}
              />
              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Sell Code",
      dataIndex: "sellCode",
      key: "sellCode",
      className: "table_action_col",
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    // //   },
    // },

    {
      title: "Product name",
      dataIndex: "product_names",
      key: "product_names",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.product_names}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Sponsor Code",
    //   dataIndex: "referByuserId",
    //   key: "referByuserId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.user_id?.referByuserId?.fullName}

    //           <p> {item?.user_id?.referByuserId?.referId}</p>
    //         </span>
    //       </>
    //     );
    //   },
    // },

    {
      title: "Color",
      dataIndex: "phone",
      key: "phone",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.colorId?.color}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Upline Id",
    //   dataIndex: "referBy",
    //   key: "referBy",
    // },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Password",
    //   dataIndex: "passwordPlan",
    //   key: "passwordPlan",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: "status",
    //       className: "table_action_col",
    //       render: (value, item, index) => {

    //         const userRank =
    //         item?.status == '0'
    //           ? 'free'
    //           : item?.status == '1'
    //           ? 'FRANCHISE'
    //           : item?.status == '2'
    //           ? 'BARON'
    //           : item?.status == '3'
    //           ? 'COUNT'
    //           : item?.status == '4'
    //           ? 'MARQUISE'
    //           : item?.status == '4'
    //           ? 'DUKE'
    //           : 'ARCH DUKE'
    //         return (
    //           <>

    // <span
    //               className="cursor-pointer"

    //             >
    //              {userRank}
    //             </span>

    //           </>
    //         );
    //       },
    //     },
    {
      title: "Amount",
      dataIndex: "sub_total",
      key: "sub_total",
    },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "payment_mode_name",
    //   key: "payment_mode_name",
    // },
    {
      title: "Delivery status",
      dataIndex: "delivery_status",
      key: "delivery_status",
    },
    // {
    //   title: "Order return",
    //   dataIndex: "orderretune",
    //   key: "orderretune",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.orderretune == "true" ? "Yes" : "No"}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip> */}
            <Tooltip title="Invoice">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Invoice
              </button>
            </Tooltip>
            <Tooltip title="View">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewProduct(item)}
              >
                View
              </button>
            </Tooltip>
            <Tooltip title="Return Order"></Tooltip>
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/sale?page=" + 1 + "&search=" + e);
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/accounts/order?page=" + e + "&&search=" + search);
  };
  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Donation"}
            subHeading={`Total ${order_list?.totalDocs || 0} Donation`}
          />
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <div className="container">
          <div className="row">
            {listdata &&
              listdata?.map((data, index) => {
                return (
                  <div className="col-12 col-sm-3">
                    <div class="item">
                      <div class="pricing__item shadow navy__blue_1">
                        <h3 class="pricing__title">{data?.title}</h3>
                        <div class="pricing__price">
                          <span class="pricing__currency">
                            <i class="fa fa-inr"></i>{" "}
                          </span>
                          {data?.price}
                        </div>
                        <p class="pricing__sentence">Rs. {data?.price}</p>

                        <Link
                          to={"/accounts/confirm_package/" + data?._id}
                          style={{ width: "100%" }}
                          class="pricing__action center-block"
                        >
                          Donate Now
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {/* <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: order_list?.totalDocs ? order_list?.totalDocs : 0,
          }}
        /> */}
      </div>
    </div>
  );
}

export default DonationComponent;
