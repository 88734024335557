import React from 'react'

function HelpWithFetured() {
  return (
    <div>
      <section id="" class="pt-30 bg-light">
    {/* <div class="container-xl">
    <div class="row diff_1 text-center mb-4">
      <div class="col-md-12">
        <h3 class="family_1 col_red">About us</h3>
        <h1 class="mb-0 mt-3 font_50">We Change Your life & world</h1>
      </div>	
     </div>
     <div class="row feat_1">
    
      <div class="col-md-6">
        <div class="feat_1r">
          
          <h1 class="mt-33 mb-3 font_50">UNNATI JEEVAN DHARA SAMAJIK SANSTHAN</h1>
          <p>UNNATI JEEVAN DHARA SAMAJIK SANSTHAN works as catalyst in bringing smile with a change to the lives they touch. In order to make sure that the underprivileged children especially girl children get access to quality education, unemployed poor people gets employment and to empower women through vocational training with different programs of handicrafts, UNNATI JEEVAN DHARA SAMAJIK SANSTHAN gives full support with its different programs organized by its volunteers to give benefits to the needy. </p>
          <h5 class="fs-6">Target: $5,000.00</h5> 
           <ul class="mb-0 mt-4">
              <li class="d-inline-block"><a class="button text-uppercase" href="#"><i class="fa fa-check-circle me-1"></i> DONATE NOW  </a></li>
               <li class="d-inline-block ml-5"><a class="button_1 text-uppercase" href="#"><i class="fa fa-play me-1"></i> Cause Video </a></li>
            </ul>
        </div>
      </div>
      <div class="col-md-6">
        <div class="feat_1l position-relative">
          <div class="feat_1l1 position-absolute w-100">
            <img src="img/5.jpeg" alt="abc" class="rounded-circle" />
          </div>
         <div class="feat_1l2 position-absolute w-100">
            <img src="img/7.jpg" alt="abc" class="rounded-circle" />
          </div> 
           <div class="feat_1l3 position-absolute w-100 text-end">
            <img src="img/6.jpg" alt="abc" class="rounded-circle" />
          </div> 
           <div class="feat_1l4 position-absolute w-100 text-end">
            <img src="img/4.jpg" alt="abc" class="rounded-circle" />
          </div>
        </div>
      </div>	
     </div>
    </div> */}

      <div class="container-xl">
      <div class="row diff_1 text-center mb-4">
      <div class="col-md-12">
        <h3 class="family_1 col_red">About us</h3>
        <h1 class="mb-0 mt-3 font_50">We Change Your life & world</h1>
        
      </div>	
     </div>
     <div class="container my-5">
    <div class="row">
       
        <div class="col-md-8 about-content">
            <h1 class="col_red">UNNATI JEEVAN DHARA SAMAJIK SANSTHAN</h1>

            <p>
            <span class="col_green">UNNATI JEEVAN DHARA SAMAJIK SANSTHAN </span> works as a catalyst in bringing smile with a change to the lives they touch.
                In order to make sure that the underprivileged children, especially girl children, get access to quality education,
                unemployed poor people get employment, and to empower women through vocational training with different programs
                of handicrafts, <span class="col_green">UNNATI JEEVAN DHARA SAMAJIK SANSTHAN </span> gives full support with its different programs organized by 
                its volunteers to give benefits to the needy.
            </p>
        </div>

       
        <div class="col-md-4 donate-section">
            <img src="img/5.jpeg" alt="Donate Support Image"/>
        </div>
    </div>
</div>

     
      </div>
    </section>
    </div>
  )
}

export default HelpWithFetured