// import { allapiAction } from "@/Redux/common/action";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";

function FooterNew() {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [menu, setmenu] = useState(false);
  const [menucate, setmenucate] = useState(false);
  const all_categories_List = useSelector((state) =>
    state?.allapi?.all_categories_List ? state?.allapi?.all_categories_List : []
  );
  const get_footer_all_data = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const [maincateindex, setmaincateindex] = useState("");
  const [subcateindex, setsubncateindex] = useState("0");

  const hendletocateShow = (e) => {
    setmaincateindex(e);
    setsubncateindex("0");
  };
  useEffect(() => {
    dispatch(allapiAction.footerPagepageListetPu({}));
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));
    } else {
      setValue("");
      setValuevendor("");
    }
  }, []);
  const hendlemenu = () => {
    if (menu) {
      setmenu(false);
    } else {
      setmenu(true);
    }
  };

  return (
    <div className="footerNew">
      <footer class="footer-section">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="widget company-intro-widget">
                  <a href="index.html" class="site-logo">
                    {/* <img
                      src="https://i.ibb.co/vLDyPtM/ak-logo-yellow.png"
                      alt="logo"
                      class="footer_logo"
                    /> */}
                    <Link class="ps-logo" to="/">
                      {/* <span class="gradient-color-text">BestBharat</span>{" "} */}
                      <img
                        src="/img/logo.png"
                        className="homeLogoicon"
                        alt=""
                        loading="lazy"
                      />
                    </Link>
                  </a>
                <h1 class="text-light">About us</h1>
                  <p >
                  UNNATI JEEVAN DHARA SAMAJIK SANSTHAN works as catalyst in bringing smile with a change to the lives they touch. 
                  In order to make sure that the underprivileged children especially girl children get access to quality education, 
                  unemployed poor people gets employment and to empower women through vocational
                   training with different programs of handicrafts, 
                   UNNATI JEEVAN DHARA SAMAJIK SANSTHAN gives full support with its different programs organized by its volunteers to give benefits to the needy.
                  </p>
                </div>
              </div>
              {/* <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="widget course-links-widget">
                  <h5 class="widget-title">Useful Links</h5>
                  <ul class="courses-link-list">
                    <li>
                      <Link to="/all-lost-and-found">
                        <i class="fas fa-long-arrow-alt-right"></i>All Lost And
                        Found
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-portal">
                        <i class="fas fa-long-arrow-alt-right"></i>Job Portal
                      </Link>
                    </li>
                    <li>
                      <Link to="/conservatism">
                        <i class="fas fa-long-arrow-alt-right"></i>Conservatism
                      </Link>
                    </li>
                    <li>
                      <Link to="/all-message">
                        <i class="fas fa-long-arrow-alt-right"></i>Message
                      </Link>
                    </li> 
                    <li>
                      <Link to="/student">
                        <i class="fas fa-long-arrow-alt-right"></i>Student
                      </Link>
                    </li>
                    <li>
                      <Link to="/blogs-news">
                        <i class="fas fa-long-arrow-alt-right"></i>Blogs
                      </Link>
                    </li>
                     <li>
                      <a href="#">
                        <i class="fas fa-long-arrow-alt-right"></i>Apps
                        Development
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            
              {/* <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="widget latest-news-widget">
                  <h5 class="widget-title">lastest news</h5>
                  <ul class="small-post-list">
                    <li>
                      <div class="small-post-item">
                        <a href="#" class="post-date">
                          July 18, 2018
                        </a>
                        <h6 class="small-post-title">
                          <a href="#">
                            Lorem Ipsum is simply dummy text of the printing.
                          </a>
                        </h6>
                      </div>
                    </li>
                    <li>
                      <div class="small-post-item">
                        <a href="#" class="post-date">
                          July 28, 2018
                        </a>
                        <h6 class="small-post-title">
                          <a href="#">
                            Lorem Ipsum is simply dummy text of the printing
                          </a>
                        </h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="widget newsletter-widget">
                  <h3 class="widget-title">Contact us</h3>
                  <div class="footer-newsletter">
                    <ul class="company-footer-contact-list">
                    <li>
                        <i class="fas fa-map-marker-alt"></i> Alwar, Rajasthan
                       
                      </li>

                      <li>
                        <i class="fas fa-phone"></i>Phone: +91 9256516230
                      </li>
                     
                      <li>
                        <i class="fas fa-envelope"></i>Email: Ujds@gmail.com
                      </li>
                      <li>
                        <i class="fas fa-globe"></i>Web: ujds.online
                      </li>
                    </ul>
                    {/* <p>
                      Sign Up to Our Newsletter to Get Latest Updates & Services
                    </p> */}
                    {/* <form class="news-letter-form">
                      <input
                        type="email"
                        name="news-email"
                        id="news-email"
                        placeholder="Your email address"
                      />
                      <input type="submit" value="Send" />
                    </form> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="widget course-links-widget">
                  <h1 class="widget-title">Account Details</h1>
                  
                  <ul class="courses-link-list text-white">
                    <li>
                    
                       
                        Reg.No.COOP/2023/ALWAR/204013
                      
                    </li>
                    <li>
                      
                      BRN NO. :8004900044000384
                    
                    </li>
                    <li>
                    PAN NO: AACAU7325F
                    
                    </li>
                    <li>
                    12A-80G NO.:AACAU7325FE20231
                    
                    </li>
                    <li>
                      
                    IFSC CODE: BARBOARYANA
                    </li>
                    <li>
                      
                    A/C No: 46910100007893
                      </li>
                    {/* <li>
                      <Link to="/job-portal">
                        <i class="fas fa-long-arrow-alt-right"></i>Job Portal
                      </Link>
                    </li>
                    <li>
                      <Link to="/conservatism">
                        <i class="fas fa-long-arrow-alt-right"></i>Conservatism
                      </Link>
                    </li>
                    <li>
                      <Link to="/all-message">
                        <i class="fas fa-long-arrow-alt-right"></i>Message
                      </Link>
                    </li>
                    <li>
                      <Link to="/student">
                        <i class="fas fa-long-arrow-alt-right"></i>Student
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-3 col-sm-3 text-sm-left text-center"></div>
              <div class="col-md-6 col-sm-6 text-sm-left text-center">
                <span class="copy-right-text">
                  ©2023 All Rights Reserved | <a href="https://ujds.online">UNNATI JEEVAN DHARA SAMAJIK SANSTHAN</a>
                 
                </span>
              </div>
              {/* <div class="col-md-6 col-sm-6">
                <ul class="terms-privacy d-flex justify-content-sm-end justify-content-center">
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      <div className="navigation--list">
        <div className="navigation__content">
          <Link
            className="navigation__item ps-toggle--sidebar footico"
            to="/"
            onClick={() => {
              setmenucate(false);
              setmenu(false);
            }}
          >
            <i className="icon-home"></i>
            <span> Home</span>
          </Link>
          <a
            className="navigation__item ps-toggle--sidebar footico"
            onClick={() => {
              hendlemenu(true);
              setmenucate(false);
            }}
          >
            <i className="icon-menu"></i>
            <span> Menu</span>
          </a>

          <a
            className="navigation__item ps-toggle--sidebar footico "
            onClick={() => {
              setmenucate(true);
              setmenu(false);
            }}
          >
            <i className="icon-list4"></i>
            <span> Categories</span>
          </a>
          <Link
            className="navigation__item ps-toggle--sidebar footico"
            to="/products"
            onClick={() => {
              setmenucate(false);
              setmenu(false);
            }}
          >
            <i className="icon-magnifier"></i>
            <span>Products</span>
          </Link>
          {/* <Link
            className="navigation__item ps-toggle--sidebar footico"
            to="/job-portal"
            onClick={() => {
              setmenucate(false);
              setmenu(false);
            }}
          >
         
            <img src="/img/businessman.png" width={20} loading="lazy" />
            <span>Job Portal</span>
          </Link> */}
        </div>
      </div>
      <div
        className={menucate ? "ps-panel--sidebar active " : "ps-panel--sidebar"}
        id="navigation-mobile"
      >
        <div className="ps-panel__header cth">
          <h3>Categories</h3>

          <div className="closebtnn">
            <img
              src="/img/close.png"
              onClick={() => {
                setmenucate(false);
              }}
              loading="lazy"
              alt=""
            />
          </div>
        </div>
        <div className="ps-panel__content mobile-header-content-area">
          <div className="mobile-menu-wrap mobile-header-border">
            <nav>
              <ul className="mobile-menu font-heading">
                {all_categories_List?.map((maincateData, inmain) => {
                  return (
                    <li
                      className={
                        maincateindex == inmain
                          ? "menu-item-has-children active"
                          : "menu-item-has-children"
                      }
                      key={inmain}
                    >
                      <span className="menu-expand">
                        <i className="fi-rs-angle-small-down"></i>
                      </span>
                      <a
                        onClick={() => {
                          hendletocateShow(inmain);
                        }}
                      >
                        {maincateData?.name}
                      </a>
                      <ul
                        className="dropdown"
                        style={
                          maincateindex == inmain ? {} : { display: "none" }
                        }
                      >
                        {maincateData?.subcates &&
                          maincateData?.subcates?.map((datasub, subind) => {
                            return (
                              <li
                                className={
                                  subind == subcateindex
                                    ? "menu-item-has-children active  "
                                    : "menu-item-has-children"
                                }
                                key={subind}
                              >
                                <span className="menu-expand">
                                  <i className="fi-rs-angle-small-down"></i>
                                </span>
                                <a
                                  href="#"
                                  onClick={() => {
                                    setsubncateindex(subind);
                                  }}
                                >
                                  {datasub?.name}
                                </a>

                                <ul
                                  className="dropdown"
                                  style={
                                    subind == subcateindex
                                      ? {}
                                      : { display: "none" }
                                  }
                                >
                                  {datasub?.subjectss &&
                                    datasub?.subjectss?.map((data, i) => {
                                      return (
                                        i < 9 && (
                                          <li key={i}>
                                            <a
                                              onClick={() => {
                                                router(
                                                  "/category-product/" +
                                                    data?.cate_sulg
                                                );
                                                setmenucate(false);
                                              }}
                                            >
                                              {data?.name}
                                            </a>
                                          </li>
                                        )
                                      );
                                    })}
                                  <li>
                                    {datasub?.subjectss?.length > 9 && (
                                      <Link
                                        to={"/category"}
                                        className="tabBtn1 active"
                                      >
                                        more
                                      </Link>
                                    )}
                                  </li>
                                </ul>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {/* <div
        className={menu ? "ps-panel--sidebar active" : "ps-panel--sidebar"}
        id="menu-mobile"
      >
        <div className="ps-panel__header">
          <h3>Menu</h3>
        </div>


        <div className="ps-panel__content">
          <ul className="menu--mobile">
            <li className="menu-item-has-children">
              <Link
                to="/"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Home
              </Link>
            </li>
            <li className="menu-item-has-children">
              <Link
                to="/conservatism"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Conservatism
              </Link>
            </li>
            <li className="menu-item-has-children">
              <Link
                to="/student"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Student
              </Link>
            </li>
            {(value || valuevendor) && (
              <li className="menu-item-has-children">
                <Link
                  to="/accounts"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Accounts
                </Link>
              </li>
            )}
           
            {valuevendor && (
              <li className="menu-item-has-children">
                <Link
                  to="/seller"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Deshboard
                </Link>
              </li>
            )}
            {(value || valuevendor) && (
              <li className="menu-item-has-children">
                <Link
                  to="/add-lost-found"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Add Lost And Found
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div> */}

      <div
        className={menu ? "ps-panel--sidebar active" : "ps-panel--sidebar"}
        id="menu-mobile"
      >
        <div className="ps-panel__header">
          <h3>Menu</h3>
        </div>
        <div className="ps-panel__content text-center">
          <div class="row">
            <div class="col-3">
              <Link
                to="/"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-home my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Home</p>
              </Link>
            </div>
            {/* <div class="col-3">
              <Link
                to="/conservatism"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-commenting my_icons_list"></i>
                <p class="menu_p_tag">Conservatism</p>
              </Link>
            </div> */}
            {/* <div class="col-3">
              <Link
                to="/student"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-group my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Student</p>
              </Link>
            </div> */}
            <div class="col-3">
              <Link
                to="/accounts"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-bank my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Accounts</p>
              </Link>
            </div>
            {/* <div class="col-3">
              <Link
                to="/vendor-login"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i
                  class="fa fa-user-secret my_icons_list"
                  aria-hidden="true"
                ></i>
                <p class="menu_p_tag">Become A Vendor</p>
              </Link>
            </div> */}
            <div class="col-3">
              <Link
                to="/seller"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-dashboard my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Deshboard</p>
              </Link>
            </div>
            {/* <div class="col-3">
              <Link
                to="/add-lost-found"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-dashboard my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Add Lost And Found</p>
              </Link>
            </div> */}
            {/* <div class="col-3">
              <Link
                to={value || valuevendor ? "/message" : "/login"}
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-dashboard my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Add Message</p>
              </Link>
            </div> */}
          </div>

          {/* <ul className="menu--mobile">
            <li className="menu-item-has-children">
              <Link
                href="/"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Home
              </Link>
            </li>
            <li className="menu-item-has-children">
              <Link
                href="/conservatism"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Conservatism
              </Link>
            </li>
            <li className="menu-item-has-children">
              <Link
                href="/student"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Student
              </Link>
            </li>
            {(value || valuevendor) && (
              <li className="menu-item-has-children">
                <Link
                  href="/accounts"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Accounts
                </Link>
              </li>
            )}
            {!valuevendor && !value && (
              <li className="menu-item-has-children">
                <Link
                  href="/vendor-login"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Become A Vendor
                </Link>
              </li>
            )}
            {valuevendor && (
              <li className="menu-item-has-children">
                <Link
                  href="/seller"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Deshboard
                </Link>
              </li>
            )}
            {(value || valuevendor) && (
              <li className="menu-item-has-children">
                <Link
                  href="/add-lost-found"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Add Lost And Found
                </Link>
              </li>
            )}
          </ul> */}
        </div>
      </div>
    </div>
  );
}

export default FooterNew;
