import React from "react";

function HomeBlog() {
  return (
    <div>
      <section id="blog_h" class="p_3">
        <div class="container-xl">
          <div class="row diff_1 text-center mb-4">
            <div class="col-md-12">
              <h3 class="family_1 col_red">What We DO</h3>
              <h1 class="mb-0 mt-3 font_50">We Change Your life & world</h1>
              {/* <h1 class="mb-0 mt-3 font_50">News & Happenings</h1> */}
            </div>
          </div>
          <div class="row blog_h1">
            <div class="col-md-4">
              <div class="blog_h1m">
                <div class="blog_h1m1 position-relative">
                  <div class="blog_h1m1">
                    <div class="grid clearfix">
                      <figure class="effect-jazz mb-0">
                        <a href="#">
                          <img src="img/8.png" class="w-100" alt="img25" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  {/* <div class="blog_h1m2 position-absolute w-100">
                    <h2 class="mb-0 bg_green d-inline-block p-3 text-white text-center">
                      18​
                      <br />
                      <span class="fs-6">JUNE</span>​
                    </h2>
                  </div> */}
                </div>
                <div class="blog_h1m2 shadow_box p-25">
                  <h4>
                    <a href="#">Working For Child Education</a>
                  </h4>
                  <p class="mt-3 mb-4">
                  "Dedicated to nurturing young minds, empowering children through education for a brighter and better future."
                  </p>
                  {/* <hr />
                  <h6 class="mt-4 mb-0 font_14">
                    <i class="fa fa-user col_red me-1"></i>{" "}
                    <a href="#">Admin</a>
                    <span class="pull-right">
                      <i class="fa fa-comment-o col_red me-1"></i>{" "}
                      <a href="#">0 Comments</a>
                    </span>
                  </h6> */}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="blog_h1m">
                <div class="blog_h1m1 position-relative">
                  <div class="blog_h1m1">
                    <div class="grid clearfix">
                      <figure class="effect-jazz mb-0">
                        <a href="#">
                          <img src="img/9.jpg" class="w-100" alt="img25" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  {/* <div class="blog_h1m2 position-absolute w-100">
                    <h2 class="mb-0 bg_green d-inline-block p-3 text-white text-center">
                      19​
                      <br />
                      <span class="fs-6">JUNE</span>​
                    </h2>
                  </div> */}
                </div>
                <div class="blog_h1m2 shadow_box p-25">
                  <h4>
                    <a href="#">Helping Poor People</a>
                  </h4>
                  <p class="mt-3 mb-4">
                  "Committed to uplifting lives by providing essential support and opportunities to the poor and underserved."
                  </p>
                  {/* <hr />
                  <h6 class="mt-4 mb-0 font_14">
                    <i class="fa fa-user col_red me-1"></i>{" "}
                    <a href="#">Admin</a>
                    <span class="pull-right">
                      <i class="fa fa-comment-o col_red me-1"></i>{" "}
                      <a href="#">0 Comments</a>
                    </span>
                  </h6> */}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="blog_h1m">
                <div class="blog_h1m1 position-relative">
                  <div class="blog_h1m1">
                    <div class="grid clearfix">
                      <figure class="effect-jazz mb-0">
                        <a href="#">
                          <img src="img/10.png" class="w-100" alt="img25" />
                        </a>
                      </figure>
                    </div>
                  </div>
                  {/* <div class="blog_h1m2 position-absolute w-100">
                    <h2 class="mb-0 bg_green d-inline-block p-3 text-white text-center">
                      17​
                      <br />
                      <span class="fs-6">JUNE</span>​
                    </h2>
                  </div> */}
                </div>
                <div class="blog_h1m2 shadow_box p-25">
                  <h4>
                    <a href="#">Helping Street Animals</a>
                  </h4>
                  <p class="mt-3 mb-4">
                  "Devoted to rescuing, protecting, and caring for street animals, ensuring they receive love and safety."
                  </p>
                    {/* <hr />
                    <h6 class="mt-4 mb-0 font_14">
                      <i class="fa fa-user col_red me-1"></i>{" "}
                      <a href="#">Admin</a>
                      <span class="pull-right">
                        <i class="fa fa-comment-o col_red me-1"></i>{" "}
                        <a href="#">0 Comments</a>
                      </span>
                    </h6> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeBlog;
