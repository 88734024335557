import React from 'react'

function HomeVision() {
  return (
    <div><section id="goal" class="p_3">
    {/* <div class="container-xl">
     <div class="row diff_1 text-center mb-4">
      <div class="col-md-12">
        <h3 class="family_1 col_red">We Change Your life & world</h3>
        <h1 class="mb-0 mt-3 font_50">Our Mission & Goals</h1>
      </div>	
     </div>
     <div class="goal_m position-relative">
       <div class="goal_m1">
         <div class="row goal_1">
      <div class="col-md-6">
         <div class="goal_1l">
           <div class="grid clearfix">
              <figure class="effect-jazz mb-0">
                <a href="#"><img src="img/15.jpg" class="w-100" alt="img25" /></a>
              </figure>
          </div>
         </div>
      </div>	
      <div class="col-md-6">
         <div class="goal_1r"></div>
      </div>
     </div>
       </div>
       <div class="goal_m2 position-absolute">
         <div class="row goal_2">
      <div class="col-md-6">
         <div class="goal_2l">
           
         </div>
      </div>	
      <div class="col-md-6 p-0">
         <div class="goal_2r p-25 px-20  bg-white shadow_box">
            <h4>Small Donations Make Bigger Impact On Someone’s Life, Act Today!</h4>
            <p class="mt-3 mb-4">Our mission is to make the world a better place for everyone. Everyone has their rights equally and we want to make sure that people can get food, education, treatment and accommodation for everyone who is living in poverty.</p>
            <div class="goal_2ri row">
              <div class="col-md-4">
                <div class="goal_2ril text-center">
                  <span class="d-inline-block bg_light"><i class="fa fa-user"></i></span>
                  <h5 class="mt-20 fs-6">Home Shelter</h5>
                </div>
              </div>
              <div class="col-md-4">
                <div class="goal_2ril text-center">
                  <span class="d-inline-block bg_light"><i class="fa fa-apple"></i></span>
                  <h5 class="mt-20 fs-6">Water  Food</h5>
                </div>
              </div>
              <div class="col-md-4">
                <div class="goal_2ril text-center border-0">
                  <span class="d-inline-block bg_light"><i class="fa fa-heart-o"></i></span>
                  <h5 class="mt-20 fs-6">Love The World</h5>
                </div>
              </div>
            </div>
         </div>
      </div>
     </div>
       </div>
     </div>
    </div> */}
<div class="container mt-5">`
<div class="row diff_1 text-center mb-4">
      <div class="col-md-12">
      <h3 class="family_1 col_red">Our Vision</h3>
      <h1 class="mb-0 mt-3 font_50">We Change Your life & world </h1>
      </div>	
     </div>`
<div class="row">
  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <img src="https://ujds.online/newujd/assets/images/resizecow.jpg" class="w-100" alt="img25" />
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title"><span class="col_green">UNNATI JEEVAN DHARA SAMAJIK SANSTHAN </span>works as catalyst in bringing smile with a change to the lives they touch. 
        unemployed poor people gets employment and to empower women through vocational training with different programs of handicrafts,
        <span class="col_green">UNNATI JEEVAN DHARA SAMAJIK SANSTHAN </span>gives full support with its different programs organized by its volunteers to give benefits to the needy.</h4>
        
        
      </div>
    </div>
  </div>
</div>
</div>
    </section></div>
  )
}

export default HomeVision   